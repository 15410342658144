import Template from "../../components/dom/template";
import PzModal from "../../components/modal";
import RemoveFromCartButton from "../../components/button-remove-from-cart";
import QuantityChangeButton from "../../components/button-change-quantity";
import toggleGiftNoteButton from '../../components/button-toggle-gift-note';
import saveGiftNoteButton from '../../components/button-save-gift-note';
import { gettext } from '../../locales/gettext';

import * as actionTypes  from "shop-packages/analytics/action-types";

import { ApiClient } from 'shop-packages';
import {pushEvent} from "shop-packages/analytics";
import { thumbnail, objectSelector as _get } from 'shop-packages/utils';
import observe, {
  getValue
} from 'shop-packages/redux/connectSelector';

import {
  giftNoteSelector,basketReducer
} from 'shop-packages/redux/basket/selectors';
import { getAnalyticsProductFromLocalStorage } from "../../utils";

export default class BasketListItem extends Template {
  static selector = ".js-basket-item";

  constructor(_$, data) {
    super(_$);
    this.$image = this._$.find(".js-basket-item-image");
    this.$imageLink = this._$.find(".js-basket-item-image-link");
    this.$productName = this._$.find(".js-basket-item-product-name");
    this.$productDescription = this._$.find(".js-basket-item-product-description");
    this.$productSize = this._$.find(".js-basket-item-product-size");
    this.$productPattern = this._$.find(".js-basket-item-product-pattern");
    this.$buttonQuantityIncrement = this._$.find(".js-basket-item-quantity-increment-button");
    this.$inputQuantity = this._$.find(".js-basket-item-quantity-input");
    this.$labelQuantity = this._$.find(".js-basket-item-quantity-label");
    this.$buttonQuantityDecrease = this._$.find(".js-basket-item-quantity-decrease-button");
    this.$retailPrice = this._$.find(".js-basket-item-retail-price");
    this.$price = this._$.find(".js-basket-item-price");
    this.$errorHolder = this._$.find(".js-basket-item-err");
    this.$priceCurrency = this._$.find('.js-basket-item-price-currency');
    this.$retailPriceCurrency = this._$.find('.js-basket-item-retail-price-currency');
    this.$removePopupButton = this._$.find('.js-basket-item-product-remove-popup');
    this.$productGiftBoxDefaultMessage = gettext('Hediye notu eklenmedi.');
    this.$productGiftBoxWrapper = this._$.find('.js-basket-gift-box-wrapper');
    this.$productGiftBoxToggleButton = this._$.find('.js-basket-item-product-gift-toggle-button');
    this.$productGiftBoxText = this._$.find('.js-basket-item-product-gift-box-note-added');
    this.$productGiftBoxSaveNoteButton = this._$.find('.js-basket-item-product-save-gift-note-button');
    this.$productGiftBoxCheckboxWrapper = this._$.find('.js-basket-item-product-gift-box-checkbox-wrapper');
    this.$productGiftBoxCheckbox = this._$.find('.js-basket-item-product-gift-box-checkbox');
    this.$productGiftBoxContentWrapper = this._$.find('.js-basket-item-product-gift-box-content-wrapper');
    this.$productGiftBoxForm = this._$.find('.js-basket-item-product-gift-box-form');
    this.$productGiftBoxCharacterLimit = this._$.find('.js-shipping-char-count');
    this.$productGiftBoxMessage = this._$.find('.js-basket-item-product-gift-box-message');

    this.$removePopupButton.on('click', this.onClickRemovePopup);

    this.setProps({ 
      basketItem: data,
      basketItemSettings: {
        giftBox: {
          active: true,
          giftNote: false,
        }
      } })
  }

  get productPk() {
    return this.props.basketItem.product.pk;
  }

  get _basketItem() {
    return this.props.basketItem;
  }

  onClickRemove = () => {
    let buttonRemove = $(".js-basket-item-product-remove");
    this.removeButton = RemoveFromCartButton(buttonRemove, this._basketItem.attributes);
    const { basketItem } = this.props;
    const { product } = basketItem;
    this.removeItemDataLayer(product, basketItem )
    $('.js-close-button').trigger('click');
  }

   favoriteAndRemove = () => {
    if (!window.GLOBALS.userLoggedIn) {
      return;
    }

    const $favouriteElem = document.querySelector('.js-basket-item-product-remove-and-fav');
    let productPk = $favouriteElem.getAttribute('data-pk');
    ApiClient.favorites.addFavourite(productPk);  
    $('.js-basket-item-product-remove').trigger('click');
    $('.js-close-button').trigger('click');
  }

  onClickRemovePopup = (el) => {
    const hasLoggedIn = window.GLOBALS.userLoggedIn;

    let productPk = el.currentTarget.getAttribute('data-pk');
    let productName = el.currentTarget.getAttribute('data-name');
    if (hasLoggedIn) {
      const content = `
      <hr>
      <p class="pz-modal-dialog__content-description">
      <b>${productName}</b> adlı ürünü sepetinden çıkardıktan sonra favoriye eklemek ister misiniz?
      </p>
      <div class="button-wrapper">
        <div appearance="outlined" data-pk="${productPk}"
          class="basket-item-remove-button pz-modal-dialog__content-action-outline js-basket-item-product-remove">
          Sil
        </div>
        <div class="basket-item-product-remove-and-fav pz-modal-dialog__content-action js-basket-item-product-remove-and-fav" data-pk="${productPk}">
          Sil ve Favorilere Ekle
        </div>
      </div>
      `;

      const className = 'basket-remove-modal';
      const title = 'Sepetten Sil';
      PzModal.show({
        title,
        content,
        className,
      });

      const removeAndFavButton = $('.js-basket-item-product-remove-and-fav');
      removeAndFavButton.on('click', this.favoriteAndRemove);

      $('.basket-remove-modal').on('click', (e) => {
        $(e.target).parent().hasClass('basket') &&
        $('.js-close-button').trigger('click');
      });
  
      $('.js-popup-close').on('click', () => {
        $('.js-close-button').trigger('click');
      });
    }

    const removeButton = $('.js-basket-item-product-remove');
    RemoveFromCartButton(removeButton, this._basketItem.attributes);
    removeButton.on('click', this.onClickRemove);
  }

  pushAnalyticsData (actionType) {
    const { basketItem, error } = this.props;
    const { product } = basketItem;

    $('.analytics-data').each(function() {
      const analyticsData = JSON.parse($(this).text());
      if (!analyticsData.type) return;
      if (analyticsData.type === actionTypes.cartViewed){
        const products = analyticsData.payload.products;
        $.each( products , function(index , value){
          for(const key in value){
            if (product.pk == value[key]){
              const analyticsData = {
                type : actionType,
                payload : {
                  products : [value]
                }
              };
              pushEvent(analyticsData);
              return;
            }
          }
        })
      }
    });
  }

  removeItemDataLayer = (product , basketItem) =>{
    const analyticProduct = getAnalyticsProductFromLocalStorage(product.base_code);

    this.pushAnalyticsData(actionTypes.productRemoved)
    const itemDiscountAmount = (+basketItem.discount_amount / basketItem.quantity).toFixed(2);
    const discountRate = ((itemDiscountAmount / +basketItem.retail_price) * 100).toFixed(2);
    const discountStatus = +basketItem.discount_amount > 0 ? 'İndirimli' : 'İndirimsiz';

    window.dataLayer.push({ ecommerce: null });

    const analyticsData = {
      currency: 'TRY',
      value:+product.price - itemDiscountAmount,
      items: [{
        item_name: this.capitalizeWords(product.name),
        item_id: product.base_code,
        price: +product.price,
        currency: 'TRY',
        index: this.basketIndex + 1,
        discount: +basketItem.discount_amount ? +basketItem.discount_amount / basketItem.quantity : 0,
        item_brand: this.capitalizeWords(product.attributes_kwargs?.integration_marka?.label),
        item_category: this.capitalizeWords(product.attributes?.integration_ust_kategori),
        item_category2: this.capitalizeWords(product.attributes?.integration_kategori),
        item_category3: this.capitalizeWords(product.attributes?.integration_alt_kategori),
        item_list_id: analyticProduct?.listIdx || '',
        item_list_name: this.capitalizeWords(analyticProduct?.listName) || this.capitalizeWords(product?.attributes?.integration_alt_kategori),
        item_variant: this.capitalizeWords(product?.attributes_kwargs?.integration_renk?.label), 
        quantity: basketItem.quantity,
        item_sku: product.sku,
        item_list_location:"Basket",
        item_discount_status:discountStatus,
        item_season: this.capitalizeWords(product?.attributes?.integration_season),
        item_material: this.capitalizeWords(product?.attributes_kwargs?.integration_karisim?.label),
        item_size: product.attributes.integration_beden1,
        item_last_price: +product.price - itemDiscountAmount,
        stock_status: 'in_stock',
        discount_rate: +discountRate,
        coupon: this.coupon,
        new_item_group_id: product.attributes.integration_urunkodu || ""
      }]
    }

    window.dataLayer.push({
      event: 'remove_from_cart',
      ecommerce: analyticsData
    });
  };
  capitalizeWords(string) {
    if (!string) return "";
    return string.split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  }
  initGiftBox = () => {
    const { basketItem, basketItemSettings } = this.props;
    const { product } = basketItem;

    if (!this.$productGiftBoxCheckboxWrapper.length) {
      return;
    }

    const giftNote = getValue(giftNoteSelector(basketItem.id));
    const useGiftBox = basketItemSettings.giftBox.active;

    this.$productGiftBoxCheckboxWrapper.attr('hidden', !useGiftBox);
    if (!useGiftBox) {
      this.$productGiftBoxContentWrapper.attr('hidden', !useGiftBox);
      return false;
    }

    observe(giftNoteSelector(basketItem.id)).subscribe(this.onGiftNoteChange);

    this.$productGiftBoxToggleButton.add(this.$productGiftBoxSaveNoteButton).attr({
      'data-pk': product.pk,
      'data-basket-item': basketItem.id
    });

    this._toggleGiftNoteButton = toggleGiftNoteButton(
      this.$productGiftBoxToggleButton,
      giftNote && giftNote.length,
      this.$productGiftBoxDefaultMessage
    );

    this._saveGiftNoteButton = saveGiftNoteButton(
      this.$productGiftBoxSaveNoteButton,
      this.$productGiftBoxMessage,
      this.$productGiftBoxForm
    );

    
  }

  onGiftNoteChange = () => {
    const { basketItem } = this.props;
    const giftNote = getValue(giftNoteSelector(basketItem.id));
    const messageAdded = 'Hediye Paketi ve Gönderim Notu Eklendi';
    const defaultMessage = 'Hediye Paketi ve Gönderim Notu Eklemek İstiyorum';

    this.$productGiftBoxCheckbox.prop('checked', !!giftNote);

    if (giftNote && giftNote.length) {
      this.$productGiftBoxMessage.val(giftNote === this.$productGiftBoxDefaultMessage ? '' : giftNote);
      this.$productGiftBoxText.html(giftNote === this.$productGiftBoxDefaultMessage ? defaultMessage : messageAdded);
      this.$productGiftBoxText.addClass(giftNote === this.$productGiftBoxDefaultMessage ? '' : 'message-add');
      this.$productGiftBoxCheckbox.addClass(giftNote === this.$productGiftBoxDefaultMessage ? '' : 'input-checked')
    } else {
      this.$productGiftBoxMessage.empty();
    }

    this.workerGiftBoxShown();
    this.setCharacterLimit();
  }

  setCharacterLimit = () => {
    this.$productGiftBoxMessage.on("input", function() {
      $(this).siblings().find('.js-shipping-char-count').html($(this).val().length);
    });
  }

  workerGiftBoxShown = () => {
    const isChecked = this.$productGiftBoxCheckbox.is(':checked');

    if (isChecked) {
      this.$productGiftBoxContentWrapper.attr('hidden', !isChecked);
    }
  }

  render = () => {
    const { basketItem, error } = this.props;
    const { product } = basketItem;
    
    const productPatternHTML = typeof product.attributes.integration_kalip_ust !== "undefined" ? product.attributes.integration_kalip_ust : product.attributes.integration_kalip_alt;
    const retail_price = (typeof product.retail_price === "undefined" || product.retail_price <= 0 || product.retail_price === product.price) ? "" : product.retail_price;
    const priceCurrency = product.price > 0 ? 'TL' : ''; 
    const retailPriceCurrency = retail_price > 0 ? 'TL' : '';
    const productImage = (product.productimage_set.length > 0) ? thumbnail(product.productimage_set[0].image, 'basket-list'): '/static_omnishop/img/noimage.jpg';

    this.$errorHolder.html(error);
    this.$image.attr('src', productImage);
    this.$imageLink.attr('href', product.absolute_url);
    this.$productName.html(product.name);
    this.$productName.attr('href', product.absolute_url);
    this.$productDescription.html(`${_get(product,'attributes_kwargs.renk.label','')} ${_get(product,'attributes_kwargs.boyut.label','')}`);
    this.$productSize.html(product.attributes.integration_beden1);
    this.$productPattern.html(productPatternHTML);
    this.$inputQuantity.val(basketItem.quantity);
    this.$labelQuantity.html(basketItem.quantity);
    this.$retailPrice.html(retail_price);
    this.$price.html(product.price);
    this.$removePopupButton.attr('data-pk', product.pk);
    this.$removePopupButton.attr('data-name', product.name);
    
    this.$priceCurrency.html(priceCurrency)
    this.$retailPriceCurrency.html(retailPriceCurrency)
    this.$buttonQuantityIncrement.attr('data-pk', product.pk);
    this.$buttonQuantityIncrement.attr('data-quantity', basketItem.quantity + 1);
    this.$buttonQuantityDecrease.attr('data-pk', product.pk);
    this.$buttonQuantityDecrease.attr('data-quantity', basketItem.quantity - 1);

    this.quantityIncrementButton = QuantityChangeButton(this.$buttonQuantityIncrement, this._basketItem.attributes);
    this.quantityDecreaseButton = QuantityChangeButton(this.$buttonQuantityDecrease, this._basketItem.attributes);

    const hasLoggedIn = window.GLOBALS.userLoggedIn;

    if(!hasLoggedIn)
    {
      this.$removePopupButton.addClass('js-basket-item-product-remove') 
      RemoveFromCartButton(this.$removePopupButton, this._basketItem.attributes);
      const $self = this;
      this.$removePopupButton.on('click', function()
      {
        const { basketItem } = $self.props;
        const { product } = basketItem;
        $self.removeItemDataLayer(product, basketItem )
      })
    }   

    const analyticProduct = getAnalyticsProductFromLocalStorage(product.base_code);

    const pushAddToCartEvent = (basket) => {
      const couponCode = basket.voucher_code || ""
      const basketIndex = basket.basketitem_set.findIndex(item => item.product.pk === product.pk)
      const basketItem = basket.basketitem_set.find(item => item.product.pk === product.pk)
      const itemDiscountAmount = (+basketItem.discount_amount / basketItem.quantity).toFixed(2);
      const discountRate = ((itemDiscountAmount / +basketItem.retail_price) * 100).toFixed(2);
      const eventData =   {
        currency: "TRY",
        value:+product.price - itemDiscountAmount,
        items: [
           {
            item_id:  product.base_code ,
            item_name:  product.name ,
            currency: "TRY",
            discount: +basketItem.discount_amount ? +basketItem.discount_amount / basketItem.quantity : 0,
            coupon: couponCode,
            index: basketIndex + 1,
            item_brand: this.capitalizeWords(product.attributes_kwargs?.integration_marka?.label),
            item_category: this.capitalizeWords(product.attributes.integration_ust_kategori), 
            item_category2: this.capitalizeWords(product.attributes.integration_kategori),
            item_category3: this.capitalizeWords(product.attributes.integration_alt_kategori),
            item_list_id: analyticProduct?.listIdx || '',
            item_list_name: analyticProduct?.listName || product.attributes.integration_alt_kategori,
            item_list_location: "Basket",
            item_variant:  product.attributes_kwargs?.integration_renk?.label , 
            quantity: 1,
            item_sku:  product.sku ,
            item_season:  product.attributes.integration_season ,
            item_material:  product.attributes_kwargs?.integration_karisim?.label?.replace('%', '') ,
            item_discount_status: +basketItem.discount_amount > 0 ? "indirimli" : "indirimsiz",
            price: +product.price,
            item_size:  product.attributes.integration_beden1,
            item_last_price: +product.price - itemDiscountAmount,
            stock_status: product.in_stock? "in_stock":"out_of_stock",
            discount_rate: +discountRate,
            new_item_group_id: product.attributes.integration_urunkodu || ""
           }
         ]
       }

       window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: eventData
      });
    };

    observe(basketReducer).subscribe(async ({ basket, pending }) => {
      if (!basket || pending) return;
      this.basketIndex = basket.basketitem_set.findIndex(item => item.product.pk === product.pk);
      this.coupon = basket.voucher_code || "";
    });

    this.quantityIncrementButton.success = (response) => {
      this.pushAnalyticsData(actionTypes.productAdded);
      pushAddToCartEvent(response.data.basket);
    };

    this.quantityIncrementButton.error = (error) => {
      this.$errorHolder.html(error.non_field_errors);
    };

    this.quantityDecreaseButton.index = this.basketIndex;
    this.quantityDecreaseButton.success = () =>{
      this.pushAnalyticsData(actionTypes.productRemoved)
      const discountStatus = +basketItem.discount_amount > 0 ? 'indirimli' : 'indirimsiz';
      const itemDiscountAmount = (+basketItem.discount_amount / basketItem.quantity).toFixed(2);
      const discountRate = ((itemDiscountAmount / +basketItem.retail_price) * 100).toFixed(2);
      window.dataLayer.push({ ecommerce: null });

      const analyticsData = {
        currency: 'TRY',
        value: +product.price - itemDiscountAmount,
        items: [{
          item_name: product.name,
          item_id: product.base_code,
          index: this.quantityDecreaseButton.index + 1,
          price: +product.retail_price,
          discount: +basketItem.discount_amount ? +basketItem.discount_amount / basketItem.quantity : 0,
          currency: 'TRY',
          item_brand: this.capitalizeWords(product.attributes_kwargs.integration_marka.label),
          item_category: this.capitalizeWords(product.attributes.integration_ust_kategori), 
          item_category2: this.capitalizeWords(product.attributes.integration_kategori),
          item_category3: this.capitalizeWords(product.attributes.integration_alt_kategori),
          item_list_id: analyticProduct?.listIdx || '',
          item_list_name: analyticProduct?.listName || product.attributes.integration_alt_kategori,
          item_variant: product.attributes_kwargs.integration_renk.label, 
          quantity: 1,
          item_list_location:"Basket",
          item_sku: product.sku,
          item_season: product.attributes.integration_season,
          item_material: product.attributes_kwargs.integration_karisim.label,
          item_discount_status: discountStatus,
          item_size: product.attributes.integration_beden1,
          item_last_price: +product.price - itemDiscountAmount,
          stock_status: 'in_stock',
          discount_rate: +discountRate,
          coupon: this.coupon,
          new_item_group_id: product.attributes.integration_urunkodu || ""
        }]
      }

      window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: analyticsData
      });
    };

    this.initGiftBox();
  }
}